import React from 'react'
import {Box,Text,Heading,Flex} from '@chakra-ui/react'
import MeinSEO from '../components/SEO'
import {StaticImage} from 'gatsby-plugin-image'


const Rabbi = () => {
  return(
    <>
     <Heading p="3" mt="10" variant="titel">Heinrich Heine: Der Rabbi von Bacharach.<br/> Stationen eines Traumas.</Heading>
   
    <Flex  mx="2"  direction={["column","column","row"]}  maxWidth="1200">
<MeinSEO htitel="Heinrich Heine: Der Rabbi von Bacharach"
  description="Das Theater Willy Praml inzeniert multimedial den Rabbi von Bacharach von H.Heine"
  keywords="Juden, Rabbi von Bacharach,Heinrich Heine"
  />
 <Box pr={["0","0","3"]} flex="1">


<StaticImage src="../images/theater/rabbi.jpeg"
   
   alt="Teaser zu Heine"/>
  
  <Text variant="zitat">„Vergib uns den Fluch, den wir zu Unrecht an den Namen der Juden hefteten. Vergib uns, dass wir dich in ihrem Fleische zum zweitenmal ans Kreuz schlugen, denn wir wussten nicht, was wir taten.“</Text>

<Text>Papst Johannes XXIII. fand 1963 diese Worte, die an der Wernerkapelle
in Bacharach angebracht sind. Werner, einen Christenknaben, sollen die Juden in einem blutigen Ritual bei ihrem Gottesdienst getötet haben. Eine der schönsten Ruinen am Mittelrhein erinnert an eines der scheußlichsten Pogrome des Mittelalters. Und Heine hat, genervt vom Antisemitismus seiner Zeit, als literarische Warnung! die Geschichte um den Rabbi und seine Frau Sara erfunden. Ein junges Paar, das in einer gruseligen Frühlingsnacht der Schlächterei an seiner Glaubensgemeinschaft entflieht – in einem Kahn den Rhein hinauf – ins rettende Frankfurt.</Text>
<Text>Die Inszenierung flankiert die Erzählung an ihrem Originalschauplatz mit weiteren Texten des deutschen, jüdischen und europäischen Autors Heinrich Heine.</Text>
<Text variant="zitat" >
„Ich bin müde und lechze nach Ruhe. Ich werde mir
eine deutsche Nachtmütze anschaffen und über beide Ohren ziehen. Wenn ich nur wüsste, wo ich jetzt mein Haupt niederlegen kann. In Deutsch­land ist es unmöglich.“
</Text>
</Box>
<Box shadow="md" bg="gray.200" px="3"  flex="1">
  <Heading mt="10" variant="title"  size="lg">Regie</Heading>
  <Text mt="0" >Willy Praml</Text>
  <Heading variant="title"  size="lg">Dramaturgie</Heading>
  <Text mt="2" >Michael Weber</Text>
  <Heading variant="title"  size="lg">Kostüm</Heading>
  <Text mt="2" >Paula Kern</Text>
  <Heading variant="title"  size="lg">Komposition/Arrangement
  </Heading>
  <Text mt="2" >Seppl Niemeyer, Timo Willecke, Wolfgang Barina</Text>
  <Heading variant="title">  Chorleitung</Heading>
  <Text>Wolfgang Barina</Text>
 
  <Heading variant="title"  size="lg">Darsteller*innen</Heading>
  <Text mt="2" >Reinhold Behling, Jakob Gail, Gabriele Graf, Birgit Heuser, Ruth Klapperich, Sam Michelson, Linus Nolte, Karl-­Heinz Schleis, Claudio Vilardo, Michael Weber
  </Text>
  <Heading variant="title"  size="lg">Musiker</Heading>
  <Text mt="2" >Carsten Braun, Jakob Rullhusen,
    Sascha Wild, Vjacheslav Golovchik sowie der Heinrich-­Heine-­Chor, Frankfurt am Main</Text>

  
  <Heading variant="title"  size="lg">Datum</Heading>
    <Text mt="2" >Samstag, 9. und 16. Juli<br/>
  Sonntag, 10. und 17. Juli,<br/>jeweils von 15:30 –20:00 Uhr</Text>
  <Heading variant="title"  size="lg">Ort</Heading><Text mt="2" >Beginn: Wernerkapelle, anschließender Parcours durch Bacharach: Rheinufer – Peterskirche – Josefskapelle – Bahnhof – Rathaussaal – Rathaushof</Text>
  </Box>
</Flex>
</>
  )
}
 export default Rabbi


  